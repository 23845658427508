<style>
.v-messages__message {
  padding-top: 2px;
}
</style>
<template>
  <v-container class="login-container" fluid>
    <v-row style="height: 100%; margin:0px">
      <v-col cols="12" md="6" lg="8" class="bottom-gradient hidden-sm-and-down">
      </v-col>
      <v-col cols="12" md="6" lg="4" class="input_section">
        <v-card
          class="mr-0 my-0 input_section input_gradient"
          style="height: 100%; padding-left: 30px; padding-right: 30px; padding-top: 50px;"
        >
          <v-card-title class="justify-center pt-15">
            <v-img src="@/assets/logo.svg" width="287" height="49" contain />
          </v-card-title>
          <v-card-title class="mx-3 mt-15">
            <v-row>
              <v-col cols="12">
                <div class="app-bold-font d-flex justify-center white--text">
                  Forgot Password?
                </div>
                <v-text-field
                  v-model="emailPhone"
                  label="Email or Phone number"
                  dense
                  rounded
                  filled
                  background-color="white"
                  class="mt-15"
                  @change="onChange"
                  full-width
                  :error-messages="errorMessage"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col class="d-flex justify-center align-center">
                <v-btn
                  text
                  class="text-none white--text"
                  rounded
                  @click="goBack"
                  large
                  width="100%"
                >
                  Back
                </v-btn>
              </v-col>
              <v-col class="d-flex justify-center align-center">
                <v-btn
                  large
                  rounded
                  color="#0A5B8A"
                  width="100%"
                  @click="sendCode"
                  :loading="loading"
                  dark
                  >Send code</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title class="justify-center"> </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      emailPhone: "",
      loading: false,
      errorMessage: null,
      rules: {
        required: value => !!value || "Required.",
        min: v => (v && v.length >= 6) || "Min 6 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : ""
      },
      showPassword: false
    };
  },
  methods: {
    ...mapActions({
      onForgotPassword: "auth/onForgotPassword"
    }),
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    phoneIsValid(number) {
      //eslint-disable-next-line
      return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g.test(
        number
      );
    },
    sendCode() {
      if (this.loading) return;
      let email = "";
      let phone = "";
      if (this.emailIsValid(this.emailPhone)) {
        email = this.emailPhone;
      } else if (this.phoneIsValid(this.emailPhone)) {
        phone = this.emailPhone;
      } else if (!this.emailPhone) {
        this.errorMessage = "Please enter detail";
        console.log("Invalid Format");
        return;
      } else {
        this.errorMessage = "Invalid Format";
        console.log("Invalid Format");
        return;
      }
      const params = {
        email,
        phone
      };
      this.loading = true;
      this.onForgotPassword(params)
        .then(() => {
          console.log(params);
          this.$router.push({ name: "verification-code", params });
        })
        .catch(error => {
          this.loading = false;
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = error.message;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.welcome-title {
  font-family: "Poppins-Bold";
  font-size: 70px;
  text-shadow: 2px 2px 12px #0006;
  color: #472583;
  margin-left: 50px;
  margin-top: 60%;
  margin-bottom: 10px;
  display: block;
}
.welcome-description {
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 50px;
  color: #240a52;
  display: block;
}
.login-container {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.bottom-gradient {
  background-image: url(../../assets/bg_login.jpg);
  background-size: cover;
  background-position: 50% 50%;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.login_img {
  -webkit-filter: drop-shadow(1px 8px 12px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(1px 8px 12px rgba(0, 0, 0, 0.4));
  margin-top: 70%;
}
.input_section {
  height: 100%;
  padding: 0px;
}

.input_gradient {
  background: linear-gradient(
    112deg,
    rgba(36, 163, 154, 0.8) -20.67%,
    rgba(157, 189, 208, 0.45) 33.07%,
    rgba(8, 68, 104, 0.8) 100.72%
  );
  background-blend-mode: multiply;
}
</style>
